.reminder-box {
   height: 100%;
   border-radius: 15px;
   background: #f2f0f7;
   padding: 15px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.reminder-item.swiper-slide {
   width: calc(100% - 72px) !important;
   padding: 0;
   height: auto;
}
.rb-left-image {
   width: 48px;
   height: 48px;
   overflow: hidden;
   border-radius: 50%;
   box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05) inset;
   background-color: #fff;
}
.rb-left-image > img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.rb-left {
   display: flex;
   align-items: center;
}
.rb-left-text > h6 {
   font-size: 20px;
   font-weight: 500;
   margin-bottom: 0;
}
.rb-left-text {
   color: #241c2d;
   font-size: 13px;
   padding-left: 16px;
}
.rb-left-text p {
   opacity: 0.6;
   margin-bottom: 0;
}
.rb-date-box p {
   margin-bottom: 0;
}
.rb-date-box {
   text-align: center;
   font-size: 12px;
   font-weight: 600;
   color: #6c27ff;
   padding: 8px 12px;
   border-radius: 12px;
   background: #f0e9ff;
}
.rb-date-box > small {
   font-size: 13px;
   font-weight: 400;
}
.reminder-arrow {
   position: absolute;
   border-radius: 50%;
   background: rgba(0, 0, 0, 0.15);
   backdrop-filter: blur(19.25px);
   z-index: 999;
   top: 41%;
   transform: translateY(-50%);
   width: 22px;
   height: 22px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.reminder-arrow.reminder-arrow-next {
   right: 38px;
}
.reminder-arrow.swiper-button-disabled {
   opacity: 0 !important;
}
.swiper-pagination-bullet {
   background-color: #c4a8ff !important;
   border-radius: 50%;
   opacity: 1;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
   background-color: #5112d6 !important
   ;
}
.reminder-arrow.reminder-arrow-prev {
   left: 38px;
}
.reminder-slider-outer {
   padding-bottom: 10px;
   position: relative;
   margin-top: -32px;
}
.reminder-slider-outer .swiper-pagination {
   bottom: -6px !important;
}

.swiper-horizontal {
   padding-bottom: 20px !important;
   touch-action: pan-y;
}
