@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body,
p {
   padding: 0px !important;
}

body {
   font-family: "Inter", sans-serif;
   background: rgb(250, 248, 255);
}

body::-webkit-scrollbar {
   display: none;
}

.nav-link {
   padding: 0px !important;
}

.cursor-pointer {
   cursor: pointer !important;
}
.ellipse-image a img {
   width: 32px;
   height: 30px;
}
.logotext {
   font-family: "Fredoka";
   cursor: pointer;
   font-size: 32px;
   font-style: normal;
   font-weight: 500;
   line-height: 39px;
   color: #000000;
}

.trundle-header {
   background: #fff !important;
   height: 72px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.trundle-header-top-margin {
   margin-bottom: 72px !important;
}

.secondary-button a {
   font-style: normal;

   font-weight: 500;
   font-size: 15px;
   line-height: 18px;
   display: flex;
   align-items: center;
   text-align: center;
   color: #6c27ff;
}
.p-button {
   background: #6c27ff;
   border: none;
   border-radius: 100px;
   padding-left: 24px;
   padding-right: 24px;
   padding-top: 9px;
   padding-bottom: 9px;
   font-size: 15px;
   font-weight: 500;
}
.p-button a {
   font-style: normal;
   font-weight: 500 !important;
   font-size: 15px;
   line-height: 18px;
   display: flex;
   align-items: center;
   text-align: center;
   color: #ffffff;
}
.documentry-hero-header {
   width: 100%;
   position: relative;
   display: flex;
   align-items: end;
}

.documentry-hero {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
}
.documentry-hero img {
   width: 100%;
   height: 362px;
   object-fit: cover;
}
.documentry-content {
   z-index: 1;
}
.main-banner {
   height: 362px;
}

.documentry-hero-container h2 {
   color: #ffffff;
   font-size: 42px;
   font-weight: 700;
}
.trans-button {
   border: none;
   border-radius: 100%;
   background: none;
}
.trip-plan {
   background: #fff;
   border-radius: 15px;
   box-shadow: 0 36px 36px rgba(125, 112, 176, 0.1);
   padding: 20px 36px;
}

.active-trip-plan {
   position: absolute;
   top: 320px;
   left: 16%;
}

.main-text {
   font-size: 17px;
   font-weight: bold;
   line-height: 20px;

   color: #241c2d;
}
.sub-text {
   font-size: 13px;
   font-weight: 400;
   line-height: 21px;
}
.country-tab {
   border: none;
   background: none;
   align-items: center;
   background: #fff;
   border-radius: 30px;
   box-shadow: 0 4px 8px rgba(132, 112, 176, 0.1);
   color: #241c2d;
   display: flex;

   padding: 8px 14px;
   border: 1px solid #6c27ff;
   gap: 10px;
   white-space: nowrap;
}

.country-tab-btn {
   align-items: center;
   background: #fff;
   border: 1px solid #dcdbdd;
   border-radius: 30px;
   box-shadow: 0 4px 8px rgba(132, 112, 176, 0.1);
   color: #241c2d;
   display: flex;

   gap: 10px;
   opacity: 0.4;
   padding: 8px 14px;
   white-space: nowrap;
}

.place-header-content p {
   color: #241c2d;
   font-size: 20px;
   font-weight: 500;
   line-height: 29px;
   margin-top: 27px;
}
.place-wrap {
   align-items: stretch;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   overflow-x: auto;
   overflow-y: hidden;
   padding-top: 12px;
   padding-bottom: 12px;
}
.todoroki-content {
   color: #241c2d;
   font-size: 16px;
   font-weight: 500;
   line-height: 20px;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}
.place-session {
   padding: 16px;
}
.nature-content {
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   color: #9f9ba3;
   display: -webkit-box;
   font-size: 13px;
   font-weight: 400;
   line-height: 16px;
   overflow: hidden;
   padding-top: 4px;
   text-overflow: ellipsis;
   font-style: italic;
}

.place-card {
   background: #fff;
   border-radius: 15px;
   flex-grow: 0;
   flex-shrink: 0;
   height: 267px;
   margin-right: 16px;
   width: 192px;
   cursor: pointer;
}

.place-card img {
   border-radius: 15px;
   height: 128px;
   width: 192px;
   object-fit: cover;
   object-position: center;
}
.seoul-content {
   color: #241c2d;
   font-size: 15px;
   letter-spacing: -0.0024em;
   line-height: 21px;
}

.show-more-content {
   background: #f0e9ff;
   border-radius: 15px;
   flex-grow: 0;
   flex-shrink: 0;
   height: 267px;
   margin-right: 16px;
   width: 192px;
}
.show-more-btn {
   align-items: center;
   background-color: initial;
   border: transparent;
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: center;
   width: 100%;
}
.show-more-btn p {
   color: #6c27ff;
   font-size: 17px;
   font-weight: 500;
   line-height: 20px;
   padding-top: 12px;
   text-align: center;
}

.place-wrap::-webkit-scrollbar {
   display: none;
}

.place-wrap {
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none; /* Firefox */
}

.weekend-header {
   height: 136px;
   width: 332px;
   margin-right: 16px;
   border: none !important;
   min-width: auto !important;
}
.weekend-show {
   height: 136px;
   width: 332px;
   margin-right: 16px;
   background: #f0e9ff;
   border-radius: 15px;
   flex-grow: 0;
   flex-shrink: 0;
}
.viewmodal-img img {
   width: 58px;
   height: 58px;
}
.bio-text {
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 24px;
   display: flex;
   align-items: center;
   color: #ffffff;
}
.date-text {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
   display: flex;
   align-items: center;
   letter-spacing: -0.0024em;
   color: #ffffff;
   opacity: 0.6;
}
.seoull-content {
   font-style: normal;
   font-weight: 300;
   font-size: 13px;
   line-height: 16px;
   color: #ffffff;
   position: relative;
}
/* .seoull-content::after {
   content: "";
   position: absolute;
   right: -6px;
   top: 27%;
   transform: translateY(-50%);
   width: 2px;
   height: 2px;
   border-radius: 50%;
   background: #cac9cd;
} */

.business-content {
   font-style: normal;
   font-weight: 400;
   font-size: 13px;
   line-height: 16px;
   text-align: center;
   color: #ffffff;
}

.weekend-header img {
   border-radius: 15px;
}
.footer-header {
   background: #210a51;
   height: 65px;
}
.footer-content p {
   color: #fff;
   font-family: Inter;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   opacity: 0.3;
}

.footer-header .footer-link {
   color: #fff;
   font-size: 13px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   opacity: 0.6;
}

.home-section-header {
   padding-bottom: 55px;
}

.activity-header {
   border-radius: 0 0 35px 35px;
   background: #fff !important;
}

.activity-header .container .row div p {
   font-size: 15px;
   line-height: 18px;
}

.activity-header .nav-item .button {
   color: #0be8c0 !important;
}

.activity-header-icon {
   height: 16px;
   width: 16px;
   object-fit: contain;
}

.activity-header .nav-item .button-active {
   border-bottom: 2px solid !important;
   border-color: rgba(108, 39, 255, 1) !important;
   color: rgba(108, 39, 255, 1) !important;
   opacity: 1 !important;
}

.activity-header .nav-item button {
   padding-bottom: 15px !important;
   margin-top: 15px !important;
   color: rgba(36, 28, 45, 1) !important;
   opacity: 0.5;
}

.acitivity-subhead div p {
   font-size: 24px;
   line-height: 24px;
}

.acitivity-subhead .divider {
   background: hsla(0, 0%, 87%, 0.9);
   height: 20px;
   width: 1px;
}
.acitivity-subhead div .count {
   color: var(--black);
   font-size: 15px;
   opacity: 0.6;
}

.filter-cities ul li {
   border: 1px solid #c4a8ff;
   border-radius: 100px;
   padding: 12px 16px;
   height: 41px;
   text-align: center;
   margin: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.filter-cities ul .active {
   background-color: #f0e9ff;
}

.filter-cities ul li a {
   color: black;
   font-size: 13.33px;
}

.secondary-button {
   background: #f0e9ff;
   border: transparent;
   border-radius: 30px;
   color: rgba(76, 27, 179, 0.94);

   font-size: 17px;
   line-height: 20px;
   width: 106px;
   height: 42px;
   padding: 15px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.card-image {
   border-radius: 15px;
   width: 100%;
   height: 131px;
   object-fit: cover;
   object-position: center;
}

.places-card div {
   border-radius: 15px;
}

.places-card-title {
   font-size: 17px;
   line-height: 20px;
   font-weight: 500;
}

.location-logo {
   width: 18px;
   height: 21px;
}

.places-card div div div div div p {
   font-size: 15px;
}

.places-tile-button {
   background: #f0e9ff;
   border: none;
   border-radius: 100px;
   color: #6c27ff;

   font-size: 17px;
   line-height: 20px;
   margin-top: 15px;
   padding: 15px;
}

.pagination {
   display: flex;
   justify-content: center;
   margin-top: 1rem;
   flex-wrap: wrap;
}

.pagination a {
   border: 1px solid #0be8c0;
   margin-right: 10px;
   margin-bottom: 10px;
   padding: 8px 16px;
   width: 40px;
   height: 36px;
   border-radius: 25px;
   align-items: center;
   justify-content: center;
   display: flex;
   font-size: 13.33px;
   color: black;
}

.pagination a:hover {
   background-color: #e0e0e0;
}

.pagination li.active a {
   border: 1px solid #0be8c0;
   border-radius: 25px;
   background-color: #0be8c0;
}

.pagination a.disabled {
   opacity: 0.5;
   cursor: not-allowed;
   background-color: #b0fff0;
}

.plan-trip-title {
   color: #241c2d;

   font-size: 20px;
   font-weight: 700;
   margin-left: 5px;
}

.dots {
   background: rgba(76, 27, 179, 0.94);
   border-radius: 50%;
   height: 10px;
   width: 10px;
}

.line {
   background: #f0e9ff;
   height: 157px;
   width: 1px;
}

.trip-card div img {
   height: 63px;
   object-fit: cover;
   object-position: center;
   border-top-right-radius: 15px;
   border-top-left-radius: 15px;
}

.trip-card {
   border-radius: 15px !important;
}

.trip-card .media .media-body div button {
   align-items: center;
   background: #f0e9ff;
   border-radius: 100px;
   color: #6c27ff;
   display: flex;
   height: 36px;
   justify-content: center;
   width: 178px;
   border: 0px;
}

.trip-card .media .media-body div img {
   width: 38px;
   height: 38px;
   object-fit: contain;
   object-position: center;
}

.trip-card .media .media-body .card-text {
   color: #241c2d;
   font-size: 13px;
   font-weight: 400;
   line-height: 16px;
   opacity: 0.6;
   padding-top: 6px;
}
.plan-trip-fluid button {
   background: #f0e9ff;
   border: transparent;
   border-radius: 30px;
   color: rgba(76, 27, 179, 0.94);

   font-size: 17px;
   width: 342px;
   padding: 15px;
   height: 50px;
}

.plan-trip-fluid {
   box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.15) !important;
   bottom: 18px;
}

.trip-plan-container {
   margin-bottom: 100px;
}

.floating-icon {
   align-items: center;
   bottom: 170%;
   display: flex;
   justify-content: center;
}

.floating-icon button img {
   object-fit: contain;
   object-position: center;
}

.container-bg {
   background-image: url("../images/background/bg-element.png");
   height: 100vh;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}

.auth-header div div img {
   width: 32px;
   height: 30px;
   object-fit: contain;
}

.auth-header div div button {
   background: #6c27ff;
   border: none;
   border-radius: 100px;
   color: #f8f8ff;

   font-size: 17px;
   width: 200px;
}

.alert-bg {
   background-image: url("../images/background/alert.png");
   width: 100%;
   align-items: center;
   background-repeat: no-repeat;
   background-size: 100% 100%;
   box-sizing: border-box;
   display: flex;
   gap: 10px;
   min-height: 61px;
   padding: 10px 9px;
   object-fit: contain;
}

.login-form input {
   outline: none;
   border: 0px;
}

.login-form .login-input {
   backdrop-filter: blur(25px);
   border: 1px solid #e2d4ff;
   border-radius: 15px;
   padding: 14px 25px;
}

.login-form .login-input input:focus-within {
   box-shadow: none;
}

.login-input label {
   font-size: 13px;
   line-height: 16px;
}

.login-form-border {
   border-radius: 32px;
}

.auth-submit-btn {
   background-color: #6c27ff;
   border-radius: 15px;
   border: 0px;
}

.error-message {
   font-size: 13px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link.active {
   background-color: transparent !important;
   border: none;
   color: white !important;
   opacity: 1;
}

.nav-tabs .nav-link {
   border: 0px !important;
   font-size: 15px;
   color: white;
}

.main-logo {
   cursor: pointer;
}

.filter-button {
   align-items: center;
   display: flex;
   gap: 10px;
   padding: 9px 16px;
   width: 106px;
   border: 0px;
   border-radius: 25px;
   background-color: #f0e9ff;
   color: rgba(76, 27, 179, 0.94);
   font-size: 17px;
}

.header-auth-tab ul li div p {
   font-size: 12px;
   font-weight: bold;
}

.header-auth-tab ul li div {
   cursor: pointer;
}
.header-auth-tab ul li div .active-color {
   color: rgb(76 27 179 / 94%);
   opacity: 1;
}

.auth-tab-opacity,
.header-auth-tab ul li div p {
   opacity: 0.4;
}

.modal-header .modal-dialog {
   width: 390px !important;
}
.gotokugi-temple-header
   .gotokugi-temple-container
   .gotokugi-temple-content
   .goto-temple-p {
   color: #1b191f;
   font-size: 19px;
   font-weight: 600;
   line-height: 24px;
   padding-top: 4px;
}
.gotokugi-temple-header
   .gotokugi-temple-container
   .gotokugi-temple-content
   .goto-temple-w {
   color: #241c2d;
   font-size: 15px;
   font-weight: 400;
   line-height: 21px;
}
.entry-container .entry-container-a {
}

.modal-header .modal-dialog .modal-content {
   border-radius: 35px;
   height: 560px;
   overflow: auto;
   width: 87%;
}
.modal-header .modal-dialog .modal-content::-webkit-scrollbar {
   display: none;
}
.modal-header .modal-dialog .modal-content {
   -ms-overflow-style: none;
   scrollbar-width: none;
}
.modal-header {
   padding: 0 !important;
   height: 0px !important;
   border: none !important;
}
.entry-container div .entry-container-p {
}
.entry-container img {
   width: 20px;
   height: 22px;
}
.get-direction-container {
   padding-top: 24px;
   width: 100%;
}
.get-direction-container a {
   align-items: center;
   background: rgba(189, 158, 255, 0.1);
   border: none;
   border-radius: 15px;
   color: #6c27ff;
   display: flex;
   font-size: 15px;
   font-weight: 600;
   gap: 10px;
   height: 66px;
   justify-content: center;
   line-height: 18px;
   width: 100%;
}

.walk-content {
   border-bottom: 1px solid hsla(0, 0%, 87%, 0.9);
   padding-bottom: 16px;
   padding-top: 12px;
}
.walk-content p {
   cursor: default;
}
.add-itinerary-button {
   background: #fff;
   border-radius: 35px 35px;
   display: flex;
   justify-content: center;
   bottom: 0px;
}
.add-itinerary-button button {
   background: #6c27ff;
   border: none;
   border-radius: 100px;
   color: #f8f8ff;

   font-size: 17px;
   line-height: 20px;
   max-width: 342px;
   padding: 15px;
   width: 100%;
}
.gotokugi-temple-header {
   background: rgba(250, 248, 255, 0.6);
   background-color: #fff;
   border-radius: 35px;
   margin-top: -50px;
   padding: 15px 36px;
   position: relative;
   z-index: 9999;
}
.modal-header .modal-dialog .modal-content {
   border-top: none !important;
}

.my-trip div p {
   font-weight: 500;
   font-size: 19px;
   line-height: 24px;
   color: black;
}

.card-title {
   font-size: 17px !important;
}

.my-trip div div button {
   padding: 14px;
   border: 1px solid #cac9cd;
   border-radius: 12px;
   color: #9f9ba3 !important;
   font-weight: 400 !important;
   font-size: 15px !important;
   line-height: 21px;
}

.my-trip .row .trip {
   /* box-shadow: 0px 4px 8px rgba(132, 112, 176, 0.1); */
   border-radius: 15px;
   padding: 16px;
   background-color: white;
}

.trip-detail {
   background: rgba(108, 39, 255, 0.9);
   border-radius: 0px 0px 35px 35px;
   padding: 32px 0px;
}

.trip-detail .container .row div .country-icon {
   width: 48px;
   height: 48px;
   object-fit: contain;
}

.trip-detail .container .row div p {
   color: white;
   font-weight: 600;
   font-size: 24px;
   line-height: 29px;
}

.trip-detail .container .row div .action-icon {
   cursor: pointer;
   width: 22px;
   height: 22px;
}

.trip-detail .container .row div button {
   padding: 14px;
   border: 1px solid #cac9cd !important;
   border-radius: 12px;
   background: transparent;
   color: #9f9ba3;
   font-weight: 400;
   font-size: 15px;
   line-height: 21px;
}

.trip-detail-recommend div div .row div .card {
   border: 0.964581px solid #dcdbdd;
   border-radius: 11.575px;
   padding: 15px;
   height: 122px;
}

.trip-detail-recommend div div .row .col-6 {
   padding: 4px;
   cursor: pointer;
}

.trip-detail-recommend div div .row div .card img {
   width: 24px;
   height: 24px;
}

.more-detail-recomend .box-shadow {
   border-radius: 15px !important;
}

.more-detail-recomend .row div div p {
   color: black;
   font-size: 15px;
   font-weight: 500;
   line-height: 20px;

   margin-top: 10px;
}

.more-detail-recomend .row .col-12 .sub-head {
   font-size: 14.4687px;
   color: black;
   font-weight: 400;
   line-height: 20px;
   opacity: 0.5;
}

.more-detail-recomend .progress {
   height: 6px;
   min-width: 250px;
}

.spending-modal-one .progress {
   height: 6px;
}

.more-detail-recomend .progress .progress-bar,
.spending-modal-one .progress .progress-bar {
   background-color: #0be8c0;
}

.more-detail-recomend-two div div p {
   font-size: 14.4553px !important;
   font-weight: 400 !important;
   letter-spacing: -0.0024em !important;
   line-height: 20px !important;
}

.more-detail-recomend-two div .total_spending {
   font-size: 23.1285px;
   font-weight: 500;
   line-height: 28px;
   padding-top: 10px;
}

.more-detail-recomend-two div .left-spending {
   color: #9f9ba3;
   font-size: 14.4553px;
   font-weight: 500;
   line-height: 17px;
}

.more-detail-recomend-two div .ruppee {
   font-weight: 500;
}

.filter-btn-item {
   background-color: initial;
   border: 1px solid #c4a8ff;
   border-radius: 100px !important;
   padding: 12px 16px;
   text-align: center;
   cursor: pointer;

   font-size: 12px;
}

.modal-common-height .filter-title {
   font-size: 16px;
   font-weight: 500;
   margin-bottom: 10px !important;
}

.filter-btn-content {
   border-bottom: 1px solid #dcdbdd;
   padding-bottom: 30px;
}

.form-label-content {
   color: #9f9ba3;
   font-size: 12px;
   line-height: 14px;
   margin-bottom: 10px;
   margin-left: 3px;
   margin-top: 20px;
}

.modal-footer button,
.profile-submit {
   background-color: rgba(108, 39, 255, 1);
   border-radius: 100px;
   height: 48px;
   font-size: 17px;
   font-weight: 500;
   border: 0px;
   color: white;
   width: 100%;
}

.calendar-modal .modal-content {
   background: linear-gradient(
      180deg,
      #ddc4ff 0%,
      rgba(255, 255, 255, 0) 31.03%
   );
   background-color: white;
   border-radius: 35px;
   box-shadow: 0 36px 36px rgba(125, 112, 176, 0.1);
   max-height: 100vh;
   max-width: 390px;
}
.calendar-modal .calendar-body {
   height: 50vh;
   overflow-y: scroll;
   padding: 20px;
}
.calendar-modal .modal-body {
   padding: 0;
}
.calendar-modal .footer-calendar {
   background: rgba(250, 247, 255, 0.6);
   /* Navigation-background */
   padding: 8px 16px;
   box-shadow: 0px 0px 8px rgba(101, 79, 146, 0.1);
   backdrop-filter: blur(15px);
   /* Note: backdrop-filter has minimal browser support */

   border-radius: 35px;
}
.react-datepicker {
   width: 100% !important;
   background-color: transparent !important;
   border: none !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
   border-color: var(--black) !important;
}
.react-datepicker__month-container {
   width: 100% !important;
}
.react-datepicker__header {
   margin: -5px;
   background-color: transparent !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
   width: 1.8rem !important;
   height: 1.8rem;
}

.react-datepicker__day--in-range {
   background: #f0e9ff !important;
   border-radius: 50% !important;
}
.react-datepicker__day--range-end {
   margin-left: 0 !important;
   background: #6c27ff !important;
   border-radius: 50% !important;
   /* padding: 1px 3px !important; */
   /* justify-content: center; */
   /* display: flex; */
   color: white !important;
   box-sizing: border-box !important;
}
.react-datepicker__day--selected {
   background: #6c27ff !important;
   border-radius: 50% !important;
   font-weight: bold !important;
   margin: 0.56rem !important;
   color: white !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
   margin: 0.56rem !important;
}

.calendar-modal .calendar-body::-webkit-scrollbar {
   display: none;
}

.calendar-modal .modal-header {
   align-items: center;
   display: flex;
   justify-content: center;
   padding: 20px 20px 10px;
}

.calendar-modal .modal-header h3 {
   color: #1b191f;

   font-size: 17px;
   font-weight: 600;
   line-height: 20px;
   padding-top: 10px;
   text-align: center;
}

.modal-dialog {
   display: flex;
   align-items: center;
   justify-content: center;
}

.modal-content {
   border: 0px !important;
   border-radius: 35px !important;
}

.dropdown-toggle::after {
   display: none !important;
}

.profile-menu {
   position: absolute;
   border-radius: 35px;
   background: white;
   width: 287px;
   z-index: 2;
   right: 110px;
   box-shadow: 0px 36px 36px rgba(125, 112, 176, 0.1);
   min-height: 334px;
}

.profilr-menu-mobile {
   position: absolute;
   background: white;
   width: 100%;
}
.profile-menu .sub-content-1 {
   background: rgba(108, 39, 255, 0.9);
   display: flex;
   justify-content: center;
   text-align: center;
   border-radius: 35px;
   align-items: center;
   box-sizing: border-box;
   padding: 33px;
}
.profile-menu-mobile .sub-content-1 {
   background: rgba(108, 39, 255, 0.9);
   display: flex;
   justify-content: center;
   text-align: center;
   border-radius: 0 0 35px 35px;
   align-items: center;
   box-sizing: border-box;
   padding: 33px;
}

.profile-menu .sub-content-1 .content-wrap p.username {
   font-weight: 500;
   font-size: 17px;
   padding-bottom: 5px;
   padding-top: 9px;
   line-height: 20px;
   color: white;
   opacity: 1;
}

.profile-menu-mobile .sub-content-1 .content-wrap p.username {
   font-weight: 600;
   font-size: 17px;
   padding-bottom: 5px;
   padding-top: 9px;
   line-height: 20px;
   color: white;
   opacity: 1;
}
.profile-menu .sub-content-1 .content-wrap p.email {
   font-weight: 400;
   font-size: 13px;
   line-height: 16px;
   opacity: 0.6;
   color: white;
}

.profile-menu-mobile .sub-content-1 .content-wrap p.email {
   font-weight: 400;
   font-size: 13px;
   line-height: 16px;
   opacity: 0.6;
   color: white;
}
.profile-menu .content-wrap > img {
   border: 2.01709px solid #c4a8ff;
   border-radius: 50%;
   height: 60px;
   width: 60px;
}
.profile-menu-mobile .content-wrap > img {
   border: 2.01709px solid #c4a8ff;
   border-radius: 50%;
   height: 60px;
   width: 60px;
}
.profile-menu .sub-content-1 .content-wrap .button-wrap {
   padding-top: 17px;
}
.profile-menu-mobile .sub-content-1 .content-wrap .button-wrap {
   padding-top: 17px;
}
.profile-menu .sub-content-1 .content-wrap .button-wrap button {
   background: #f0e9ff;
   color: blue;
   border-radius: 30px;

   border: transparent;
   max-width: 342px;
   font-size: 17px;
   padding: 15px;
   line-height: 20px;
   font-weight: 500;
   font-size: 15px;
}

.profile-menu-mobile .sub-content-1 .content-wrap .button-wrap button {
   background: #f0e9ff;
   color: blue;
   border-radius: 30px;

   border: transparent;
   max-width: 342px;
   font-size: 17px;
   padding: 15px;
   line-height: 20px;
   font-weight: 500;
   font-size: 15px;
}
.profile-menu .sub-content-2 .content-wrap {
   padding: 33px;
   box-sizing: border-box;
}

.profile-menu-mobile .sub-content-2 .content-wrap {
   box-shadow: 0 4px 8px rgba(132, 112, 176, 0.1);
   margin: 21px 15px;
   padding: 20px;
   border-radius: 15px;
}

.profile-menu .sub-content-2 .content-wrap li:not(:nth-of-type(2)) {
   padding-bottom: 22px;
   border-bottom: 1px solid #dcdbdd;
}
.profile-menu-mobile .sub-content-2 .content-wrap li:not(:nth-of-type(2)) {
   padding-bottom: 22px;
   border-bottom: 1px solid #dcdbdd;
}
.profile-menu .sub-content-2 .content-wrap li:not(:nth-of-type(1)) {
   padding-top: 22px;
}

.profile-menu-mobile .sub-content-2 .content-wrap li:not(:nth-of-type(1)) {
   padding-top: 22px;
}
.dropdown-menu.show {
   border: 0px;
   background: transparent;
   top: 0px;
}

.profile-modal-title {
   color: #1b191f;

   font-size: 17px;
   font-weight: 600;
   line-height: 20px;
   padding-top: 10px;
   text-align: center;
}

.profile-modal-content .modal-content {
   background: linear-gradient(180deg, #ddc4ff9c, hsla(0, 0%, 100%, 0) 23.79%);
   background-color: #fff;
}

.profile-modal-content
   .modal-dialog
   .modal-content
   .modal-body
   .row
   .col-3
   div
   p {
   font-size: 13.5px;
}

.avatar-modal .modal-content {
   background: linear-gradient(
      180deg,
      #ddc4ff9c 0%,
      rgba(255, 255, 255, 0) 23.79%
   );
   background-color: white;
   width: 390px !important;
}
.avatar-modal .avatar-wrap {
   padding: 20px;
}
.avatar-modal .selected-avatar {
   width: 110px;
   height: 110px;
}
.avatar-modal .choose-avatar {
   font-weight: 600;
   font-size: 17px;
   line-height: 20px;
   color: black;
   margin-top: 25px;
}
.avatar-modal .select-avatar .active img {
   border-radius: 50%;
   border: 2px solid #3903ad;
}
.select-avatar img {
   height: 74px;
   width: 74px;
}
.select-avatar {
   display: flex;
   flex-wrap: wrap;
   gap: 12px;
   justify-content: center;
   align-items: center;
   margin-top: 24px;
}

/* june 15 css  */

.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .bottom-process-btn
   img {
   margin-bottom: 30px;
}
.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .bottom-process-btn
   button {
   background: #6c27ff;
   border: none;
   border-radius: 100px;
   color: #f8f8ff;

   font-size: 17px;
   line-height: 20px;
   padding: 15px;
   width: 100%;
}
.modal-header .modal-dialog .modal-content .relative-process-header {
   height: 452px;
   position: relative;
}
.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .bottom-process-btn {
   bottom: 0;
   position: absolute;
}
.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .form-label-header
   .form-label-container {
   -webkit-box-align: center;
   align-items: center;
   cursor: default;
   display: flex;
   flex-wrap: wrap;
   -webkit-box-pack: justify;
   justify-content: space-between;
   min-height: 38px;
   position: relative;
   transition: all 100ms ease 0s;
   background-color: rgb(255, 255, 255);
   border-color: rgb(216, 216, 216);
   border-radius: 15px;
   border-style: solid;
   border-width: 1px;
   box-shadow: none;
   box-sizing: border-box;
   height: 3.5rem;

   outline: 0px !important;
   width: 100%;
   color: rgb(128, 128, 128);
}
.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .form-label-header
   .form-label-content {
   grid-area: 1 / 1 / 2 / 3;
   color: rgb(128, 128, 128);
   margin-left: 2px;
   margin-right: 2px;
   box-sizing: border-box;
}
.option-aus-content img {
   width: 50px;
   height: 50px;
}
.modal-header .modal-dialog .modal-content .country-content {
   align-items: center;
   display: flex;
   justify-content: center;
}
.modal-header .modal-dialog .modal-content .country-content h3 {
   color: #1b191f;

   font-size: 16px;
   font-weight: 600;
   line-height: 20px;
   padding-top: 10px;
   text-align: center;
}
.modal-header .modal-dialog .modal-content .location-header-r .location-text-r {
   font-style: normal;
   font-weight: 600;
   color: #1b191f;
   font-size: 17px;
}
.modal-header .modal-dialog .modal-content .location-header-r {
   height: 482px;
   position: relative;
}
.modal-header
   .modal-dialog
   .modal-content
   .location-header-r
   .location-desitination-r {
   color: #9f9ba3;
   font-size: 12px;
   line-height: 14px;
   margin-bottom: 10px;
   margin-left: 3px;
   margin-top: 20px;

   font-style: normal;
   font-weight: 600;
}
.modal-header
   .modal-dialog
   .modal-content
   .location-header-r
   .bottom-process-btn
   button {
   background: #6c27ff;
   border: none;
   border-radius: 100px;
   color: #f8f8ff;

   font-size: 17px;
   line-height: 20px;
   padding: 15px;
   width: 100%;
}
.modal-header
   .modal-dialog
   .modal-content
   .location-header-r
   .bottom-process-btn {
   position: absolute;
}
.modal-content {
   border: none !important;
}
.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .form-label-header
   .r-img-lael
   img {
   margin-right: 20px;
   font-size: 15px;

   text-transform: uppercase;
}
.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .form-label-header
   .r-drop-lael
   .r-img-lael {
   font-size: 15px;

   text-transform: uppercase;
   border-bottom: 1px solid rgb(216, 216, 216);
   padding: 10px;
}
.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .form-label-header
   .r-drop-lael {
   height: 290px;
   overflow: scroll;
}
.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .form-label-header
   .r-drop-lael::-webkit-scrollbar {
   display: none;
}

.modal-header
   .modal-dialog
   .modal-content
   .relative-process-header
   .form-label-header
   .r-drop-lael {
   -ms-overflow-style: none;
   scrollbar-width: none;
}

.trip-modal-header .trip-modal-container .trip-popup-modal {
   background: rgba(108, 39, 255, 1);
   padding: 19px;
   border-radius: 35px 35px 0 0;
}
.trip-modal-header .trip-modal-container .trip-popup-modal .trip-popup p {
   color: #fff;
   font-size: 17px;
   font-weight: 500;
   line-height: 20px;
}
.tab-gl-header {
   align-items: center;
   border: none;
   gap: 20px;
   justify-content: center;
   margin: 0 auto;
   max-width: none;
   background: rgba(108, 39, 255, 1);
   border-radius: 0 0 35px 35px;
}

.tab-general-content {
   color: #fff;
   opacity: 1;
   font-size: 13px;
   line-height: 16px;

   padding: 10px 23px;
}
.tab-ll-content {
   color: #fff;
   opacity: 1;
   font-size: 13px;
   line-height: 16px;

   padding: 10px 23px;
}

.tips-s-header .tips-s-container .tip-start-content {
   background-size: cover;
   border-radius: 15px;
   box-shadow: 0 4px 8px rgba(132, 112, 176, 0.1);
   box-sizing: border-box;
   height: 100%;
   margin-bottom: 16px;
   padding: 16px;
   width: 100%;
}
.tips-s-header .tips-s-container .tip-start-content .tip-note-start-content p {
   color: #e73072;
   font-size: 36px;
   font-style: italic;
   font-weight: 500;
   letter-spacing: -0.06em;
   line-height: 30px;
   opacity: 0.1;
}
.tips-s-header .tips-s-container .tip-start-content .trip-note-content p {
   color: #9f9ba3;
   font-size: 15px;
   font-weight: 400;
   letter-spacing: -0.0024em;
   line-height: 21px;
   padding-top: 4px;
   word-break: break-word;
}

.tab-gl-header {
   position: sticky;
   top: 58px;
}
.learn-l-header .learn-l-container .lear-korean-content h3 {
   color: #241c2d;
   font-size: 24px;
   font-weight: 600;
   line-height: 29px;
   padding-top: 16px;
}
.learn-l-header .learn-l-container .lear-korean-content p {
   color: #9f9ba3;
   font-size: 15px;
   font-weight: 400;
   letter-spacing: -0.0024em;
   line-height: 21px;
   padding-top: 4px;
   word-break: break-word;
}
.learn-l-header
   .learn-l-container
   .learn-blk-lan-hearder
   .learn-blk-lan-content
   .learn-blk-lan
   p {
   font-weight: 500;
   font-size: 15px;
}
.learn-l-header
   .learn-l-container
   .learn-blk-lan-hearder
   .learn-blk-lan-content {
   background: #fff;
   border-radius: 15px;
   box-shadow: 0 4px 8px rgba(132, 112, 176, 0.1);
   margin-top: 12px;
   padding: 24px 18px;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   .emergency-content
   p {
   align-items: center;
   display: flex;
   justify-content: center;
   padding: 20px 20px 10px;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   .emergency-call-content
   P {
   color: #241c2d;
   font-size: 17px;
   font-weight: 600;
   line-height: 20px;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-call-hotline-content
   .emergency-help-content {
   background: #fff;
   border-radius: 15px;
   box-shadow: 0 8px 16px rgba(125, 112, 176, 0.1);
   box-sizing: border-box;
   padding: 16px;
   width: 100%;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-call-hotline-content
   .emergency-help-content
   p {
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-content
   p {
   color: #241c2d;
   font-size: 17px;
   font-weight: 600;
   line-height: 20px;
}

.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-help-hotline-number-content {
   background: #fff;
   border-radius: 15px;
   box-shadow: 0 8px 16px rgba(125, 112, 176, 0.1);
   box-sizing: border-box;
   padding: 16px;
   width: 100%;
}

.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-help-hotline-number-content
   .hotline-number
   h4 {
   color: #241c2d;
   font-size: 24px;
   font-weight: 700;
   line-height: 29px;
   padding-bottom: 4px;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-help-hotline-number-content
   .hotline-number
   .travel-h {
   color: #241c2d;
   font-size: 17px;
   font-weight: 600;
   line-height: 20px;

   margin-bottom: 10px;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-help-hotline-number-content
   .hotline-number
   .travel-p {
   opacity: 0.7;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-help-hotline-number-content
   .hotline-number {
   border-bottom: 1px solid hsla(0, 0%, 87%, 0.9);
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-help-hotline-number-content
   .gt-img-lan-content
   p {
   color: #9f9ba3;
   font-size: 15px;
   font-weight: 400;
   letter-spacing: -0.0024em;
   line-height: 21px;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-help-hotline-number-content
   .gt-img-lan-content {
   gap: 16px;
   padding-top: 16px;
}
.modal-header
   .modal-dialog
   .modal-content
   .emergency-popup-header
   .emergency-popup-container
   div
   .emergency-call-hotline-header
   .emergency-help-hotline-number-content
   .gt-img-lan-content
   img {
   width: 50px;
   height: 50px;
}
.modal-header .modal-dialog .emergency-container {
   height: 535px;
}

.profile-modal-content .modal-content {
   width: 390px !important;
}

.edit-modal .modal-content {
   overflow-y: hidden;
}
.edit-modal .modal-body {
   padding: 0px;
}

.image-wrap button {
   color: blue;
   font-weight: 600;
}

.profile-edit-modal .model-content {
   width: 80% !important;
}

.edit-modal .edit-profile-form,
.edit-profile-form {
   padding: 20px;
}

.edit-profile-form::-webkit-scrollbar {
   display: none;
}

.edit-profile-form {
   -ms-overflow-style: none;
   scrollbar-width: none;
}

.edit-modal .edit-profile-form,
.edit-profile-form {
   padding: 20px;
}

.edit-profile-form {
   max-height: 600px;
   overflow-y: scroll;
}

.edit-profile-form:focus,
.edit-profile-form:active {
   outline: none;
   border: none;
   box-shadow: none !important;
}

.edit-profile-form-mobile {
   height: 100vh !important;
   padding: 20px;
}

.edit-modal .prof-img {
   border: 2.01709px solid #c4a8ff;
   border-radius: 50%;
   height: 64px;
   width: 64px;
   box-sizing: border-box;
}

.form-control.gender [type="radio"] + img {
   cursor: pointer;
}
.form-control.gender [type="radio"] + label svg path {
   fill: #1b191f;
   opacity: 0.4;
}

/* CHECKED STYLES */
.form-control.gender [type="radio"]:checked + label svg path {
   fill: var(--blue);
   opacity: 1;
}
.form-control.gender [type="radio"]:checked + label:nth-of-type(2) {
   border-radius: 0;
}
.form-control.gender [type="radio"]:checked + label:nth-of-type(3) {
   border-radius: 0px 12px 12px 0px;
}
.form-control.gender {
   display: flex;
   border: 1px solid #e2d4ff;
   border-radius: 15px;
   padding: 3px;
   /* margin-bottom: 23px; */
}
.custom-border {
   height: 52px;
   width: 1px;
   background-color: #e2d4ff;
   margin-right: 2px;
   margin-left: 2px;
}
.form-control.gender [type="radio"] + label {
   border-radius: 12px 0px 0px 12px;
   padding: 14px 11px;
   height: 52px;
   opacity: 0.3;
   border-radius: 12px 0px 0px 12px;
   box-sizing: border-box;
   align-items: center;
   gap: 12px;
   font-weight: 500;
   font-size: 15px;
   border-right: 1px solid #e2d4ff;
   width: 100%;
   line-height: 18px;
   display: flex;
}
.form-control.gender [type="radio"]:checked + label {
   background: #f0e9ff;
   border-radius: 12px 0px 0px 12px;
   padding: 14px 11px;
   height: 52px;
   opacity: 1;
   box-sizing: border-box;
   color: var(--blue);
}
.form-control.gender [type="radio"]:checked + .label {
   font-weight: 500;
   font-size: 15px;
   line-height: 18px;
   color: var(--blue);
}
/*profile photo modal*/
.profile-modal .modal-content {
   background: linear-gradient(
      180deg,
      #ddc4ff9c 0%,
      rgba(255, 255, 255, 0) 23.79%
   );
   background-color: white;
}

input[type="file"] {
   width: 90px;
   color: transparent;
}

.events-container div .event-name {
   font-size: 17px;
   font-weight: 500;
}

.events-container div .event-date {
   font-size: 15px;
   font-style: normal;
   font-weight: 400;
   opacity: 0.6;
   text-align: left;
}

.events-container div .event-place {
   font-size: 13px;
   font-weight: 300;
}

.event-popup-border-rounded-one {
   border: 1px solid #dcdbdd;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   margin-bottom: 15px;
   margin-top: 15px;
}

.event-popup-border-rounded {
   border: 1px solid;
   border-top: 0px;
}

.location-calender {
   border: 1px solid #dcdbdd;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   margin-bottom: 15px;
   margin-top: 15px;
}

.location-div {
   background: #fff;
   display: flex;
   margin-left: 15px;
   margin-right: 15px;
   padding-bottom: 10px;
   padding-top: 10px;
}

.location-calender-subdiv {
   margin-right: 13px;
   margin-top: 3px;
}
.location-title-text {
   color: #241c2d;

   font-size: 15px;
   font-weight: 500;
   line-height: 20px;
}

.location-address-text {
   color: #241c2d;

   font-size: 17px;
   font-weight: 400;
   opacity: 0.6;
}

.calender-div,
.location-div {
   background: #fff;
   display: flex;
   margin-left: 15px;
   margin-right: 15px;
   padding-bottom: 10px;
   padding-top: 10px;
}

.description-text {
   color: #241c2d;

   font-size: 17px;
   font-weight: 400;
   line-height: 21px;
   margin-bottom: 15px;
   opacity: 0.6;
}

.bookmark-icon-event {
   width: 53px;
   height: 53px;
   position: absolute;
   top: -25px;
   right: 25px;
   cursor: pointer;
}

.help-modal-home .modal-header {
   align-items: center;
   display: flex;
   justify-content: center;
   padding: 20px 20px 10px;
}

.help-modal-home .modal-header .title {
   color: #1b191f;
   font-size: 17px;
   font-weight: 500;
   line-height: 20px;
   padding-top: 10px;
   text-align: center;
}

.help-modal-home .cancellation-policy .content {
   color: #9f9ba3;
   font-size: 15px;
   font-weight: 400;
   letter-spacing: -0.0024em;
   line-height: 21px;
   padding-top: 16px;
}

.help-modal-home .cancellation-policy {
   background: #fff;
   border-radius: 15px;
   padding: 24px 16px;
}

.help-modal-home .cancellation-policy .content-title {
   color: #241c2d;
   color: var(--black);
   font-size: 20px;
   font-weight: 600;
   line-height: 24px;
}

.help-modal-home .modal-content {
   background-color: #fff;
   border-radius: 35px;
   box-shadow: 0 36px 36px rgba(125, 112, 176, 0.1);
   height: 400px;
   max-height: 50vh;
   max-width: 390px;
   position: relative;
}

.help-modal-home .modal-content .modal-body {
   background: linear-gradient(180deg, #ddc4ff, hsla(0, 0%, 100%, 0) 12.79%);
   border-radius: 35px;
   overflow: auto;
}

.help-modal-home .modal-content .modal-body::-webkit-scrollbar {
   display: none;
}

.input-wrap input {
   border: transparent;
   color: #0b041a;
   background: transparent;
   padding: 0px;
   font-size: 17px;
   width: 100%;
   line-height: 21px;
   opacity: 1;
   outline: none;
   border: none;
}

.input-wrap {
   border: 1px solid #e2d4ff;
   -webkit-backdrop-filter: blur(25px);
   backdrop-filter: blur(25px);
   border-radius: 15px;
   padding: 14px 25px;
}

.input-wrap .label {
   font-size: 13px;
   line-height: 16px;
   color: #3c3c43;
}

.edit-modal .modal-content {
   overflow-y: hidden;
}
.edit-modal .modal-body {
   padding: 0px;
}

.edit-modal .prof-img {
   border: 2.01709px solid #c4a8ff;
   border-radius: 50%;
   height: 64px;
   width: 64px;
   box-sizing: border-box;
}
.compact-inputfields .input-wrap:not(:nth-of-type(2)) {
   border-radius: 15px 15px 0px 0px;
}
.compact-inputfields .input-wrap:not(:nth-of-type(1)) {
   border-radius: 0px 0px 15px 15px;
}
.image-wrap .transparent-button {
   color: var(blue);
   font-weight: 500;
}
.input-fields-content {
   padding-top: 26px;
}
[type="radio"],
.form-control.gender [type="radio"] {
   position: absolute;
   opacity: 0;
   width: 0;
   height: 0;
}

.form-control.gender [type="radio"] + img {
   cursor: pointer;
}
.form-control.gender [type="radio"] + label svg path {
   fill: #1b191f;
   opacity: 0.4;
}

.form-control.gender [type="radio"]:checked + label svg path {
   fill: blue;
   opacity: 1;
}
.form-control.gender [type="radio"]:checked + label:nth-of-type(2) {
   border-radius: 0;
}
.form-control.gender [type="radio"]:checked + label:nth-of-type(3) {
   border-radius: 0px 12px 12px 0px;
}
.form-control.gender {
   display: flex;
   border: 1px solid #e2d4ff;
   border-radius: 15px;
   padding: 3px;
}
.custom-border {
   height: 52px;
   width: 1px;
   background-color: #e2d4ff;
   margin-right: 2px;
   margin-left: 2px;
}
.form-control.gender [type="radio"] + label {
   border-radius: 12px 0px 0px 12px;
   padding: 14px 11px;
   height: 52px;
   opacity: 0.3;
   border-radius: 12px 0px 0px 12px;
   box-sizing: border-box;
   align-items: center;
   gap: 12px;
   font-weight: 500;
   font-size: 15px;
   border-right: 1px solid #e2d4ff;
   width: 100%;
   line-height: 18px;
   display: flex;
}
.form-control.gender [type="radio"]:checked + label {
   background: #f0e9ff;
   border-radius: 12px 0px 0px 12px;
   padding: 14px 11px;
   height: 52px;
   opacity: 1;
   box-sizing: border-box;
   color: var(--blue);
}
.form-control.gender [type="radio"]:checked + .label {
   font-weight: 500;
   font-size: 15px;
   line-height: 18px;
   color: var(--blue);
}

.r-input-div-normal-dob-country {
   width: 100%;
   box-sizing: border-box;
   background: #fbf9ff;
   border: 1px solid #e2d4ff;
   border-radius: 15px;
   padding: 14px 20px;
   display: flex;
   flex-direction: column;
}

.r-label {
   font-weight: 600;
   font-size: 13px;
   color: #3c3c43;
}

.r-input-tag {
   background-color: transparent;
   border: none;
   outline: none;
   width: 100%;
   box-sizing: border-box;

   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   color: #000000;
}

.otp-v-header .otp-v-container .otp-v-content h3 {
   font-size: 34px;
   font-style: normal;
   line-height: 41px;
}
.otp-v-header .otp-v-container .otp-v-content p {
   color: #545257;
   font-size: 17px;
   line-height: 21px;
   margin-bottom: 30px;
   opacity: 0.6;
}
.otp-v-header {
   background-color: #fff;
   border-radius: 31.6194px;
   box-sizing: border-box;
   max-width: 440px;
   padding: 30px !important;
   position: relative;
   display: flex;
   justify-content: center;
   margin: auto auto 40px;
   /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; */
}
.otp-input Input {
   border: 1px solid #e2d4ff;
   border-radius: 15px;
   padding: 20px;
   color: #000000 !important;
   height: 68px;
   width: 51px !important;
   margin-left: 6px;
}
.otp-input {
   margin-top: 25px;
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
}
.resend-otp-btn button {
   border: none;
   background: none;
}
.alert-content {
   align-items: center;
   background: url("../images/background/alert.png");
   background-repeat: no-repeat;
   background-size: 100% 100%;
   box-sizing: border-box;
   display: flex;
   gap: 10px;
   max-width: 456px;
   min-height: 61px;
   padding: 10px 9px;
   width: 100%;
}
.alert-content p {
   color: #1d005c;
   font-size: 15px;
}

.sub-al-btn {
   background: #6c27ff;
   border: none;
   display: flex;
   justify-content: center;
   margin-top: 20px;
   border-radius: 100px;
   color: #f8f8ff;

   font-size: 17px;
   line-height: 20px;
   max-width: 342px;
   padding: 15px;
   width: 100%;
}
.otp-input-header {
   height: 65vh;
}
.otp-v-container .alert-container {
   bottom: 40px;
   left: 0;
   margin: auto;
   position: absolute;
   right: 0;
   width: 80%;
}
.otp-input input:focus {
   outline: none;
}

.filter-button-sm {
   bottom: 20px;
   display: block;
   left: 0;
   margin: auto;
   position: fixed;
   right: 0;
   width: 125px;
   z-index: 99;
   border: 0px;
   border-radius: 25px;
   background-color: #f0e9ff;
   color: rgba(76, 27, 179, 0.94);
   font-size: 17px;
}

.header-auth-tab-mobile-shadow {
   box-shadow: 0px 0px 8px 0px rgba(101, 79, 146, 0.1);
   border-radius: 20px 20px 0 0;
}

.my-trip-mobile-header {
   background: rgba(108, 39, 255, 0.9);
   border-radius: 0 0 35px 35px;
   box-sizing: border-box;
   display: block;
   padding: 30px;
   height: 105px;
}

.my-trip-mobile-header-explore {
   background: rgba(108, 39, 255);
   height: 72px;
   position: fixed;
   z-index: 1;
}
.my-trip-mobile-header-explore img {
   top: 17px;
   left: 25px;
}

.mobile-header-title {
   color: #fff;
   font-size: 17px;
   font-weight: 600;
}

.cancellation-policy-mobile .content-title {
   font-size: 16px !important;
   font-weight: normal !important;
}

.back-button {
   position: absolute;
   top: 33px;
   left: 25px;
}
.tab-gllll-container {
   -webkit-backdrop-filter: blur(6px);
   background: rgba(214, 207, 230, 0.4);
   border-radius: 30px;
   bottom: 30px;
   box-shadow: 0 36px 36px rgba(125, 112, 176, 0.1);
   display: block;
   left: 0;
   margin: auto;
   padding: 4px;
   position: fixed;
   right: 0;
   width: 220px;
   z-index: 999;
}
.locationtab-img-btn {
   align-items: center;
   background: white;
   border-radius: 40px;
   display: flex;
   height: 56px;
   justify-content: center;
   width: 96px;
}
.grid-view-img-btn {
   align-items: center;
   border-radius: 40px;
   display: flex;
   height: 56px;
   justify-content: center;
   width: 96px;
}
.c-gory-l-header {
   grid-gap: 10px;
   display: grid;
   gap: 10px;
   grid-template-columns: repeat(3, minmax(0, 1fr));
   margin-top: 20px;
}

.c-gory-l-header .c-gory-l-c .card img {
   width: 30px;
}
.c-gory-l-header .c-gory-l-c .card {
   padding: 20px;
   max-height: 106px;
}
.c-gory-l-header .c-gory-l-c .card p {
   font-size: 12px !important;
}

.more-reccomend-container .col img {
   width: 35px;
   height: 35px;
   object-fit: contain;
}

.more-reccomend-container .col {
   height: 152px !important;
}

.weekly-content [type="radio"] {
   -webkit-appearance: none;
   position: relative;
   width: 14px;
   height: 14px;
   border-radius: 50%;
   background: #fff;
   border: 1px solid #ddd !important;
   width: 20px;
   height: 20px;
   cursor: pointer;
   opacity: 1;
}

.weekly-content [type="radio"]:checked {
   border: none;
   background-color: #5000fc;
   border-radius: 50%;
   width: 20px;
   height: 20px;
}
.weekly-content [type="radio"]::before {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 12px;
   height: 12px;
   pointer-events: none;
   background: url("../images/icon/check_small.png");
   background-size: contain;
   display: none;
   text-align: center;
   vertical-align: middle;
}
.weekly-content [type="radio"]:checked::before {
   display: block;
}

.monthly-content [type="radio"] {
   -webkit-appearance: none;
   position: relative;
   width: 14px;
   height: 14px;
   border-radius: 50%;
   background: #fff;
   border: 1px solid #ddd !important;
   width: 20px;
   height: 20px;
   cursor: pointer;
   opacity: 1;
}

.monthly-content [type="radio"]:checked {
   border: none;
   background-color: #5000fc;
   border-radius: 50%;
   width: 20px;
   height: 20px;
}
.monthly-content [type="radio"]::before {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 12px;
   height: 12px;
   pointer-events: none;
   background: url("../images/icon/check_small.png");
   background-size: contain;
   display: none;
   text-align: center;
   vertical-align: middle;
}
.monthly-content [type="radio"]:checked::before {
   display: block;
}

.no-data-text {
   font-size: 17px !important;
   font-weight: 500 !important;
}

.no-data-text-child {
   font-size: 15px !important;
   color: rgba(159, 155, 163, 1) !important;
}

.plan-trip-button {
   height: 48px;
   width: 172px;
   border-radius: 100px !important;
   background-color: rgba(108, 39, 255, 1);
}

.plan-trip-button p {
   font-size: 17px !important;
}

.filter-batch {
   left: 90px;
   bottom: 25px;
   height: 25px;
   width: 25px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 12px;
}

.css-13cymwt-control {
   border-radius: 15px !important;
   height: 56px !important;
}

.p-select-content h3 {
   font-size: 17px;
   text-align: center;
}
.p-select-content {
   background: linear-gradient(180deg, #ddc4ff, hsla(0, 0%, 100%, 0) 23.79%);
}
.p-select-content Form div [type="radio"] {
   -webkit-appearance: none;
   position: relative;
   width: 14px;
   height: 14px;
   border-radius: 50%;
   background: #fff;
   border: 1px solid #ddd !important;
   width: 20px;
   height: 20px;
   cursor: pointer;
   opacity: 1;
}

.country-radiobutton-c:checked {
   border: none;
   background-color: #5000fc;
   border-radius: 50%;
   width: 20px;
   height: 20px;
}

.country-radiobutton-c::before {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 12px;
   height: 12px;
   pointer-events: none;
   background: url("../images/icon/check_small.png");
   background-size: contain;
   display: none;
   text-align: center;
   vertical-align: middle;
}
.country-radiobutton-c:checked::before {
   display: block;
}

.p-select-content Form div [type="radio"]:checked {
   border: none;
   background-color: #5000fc;
   border-radius: 50%;
   width: 20px;
   height: 20px;
}
.p-select-content Form div [type="radio"]::before {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 12px;
   height: 12px;
   pointer-events: none;
   background: url("../images/icon/check_small.png");
   background-size: contain;
   display: none;
   text-align: center;
   vertical-align: middle;
}
.p-select-content Form div [type="radio"]:checked::before {
   display: block;
}

.p-select-container Form div p {
   margin-bottom: 5px;
}

.p-select-content Form div label .start-p-content {
   color: #241c2d;
   font-size: 17px;
   font-weight: 500;
   line-height: 20px;
}
.p-select-content Form div label .build-p-content {
   color: #241c2d;
   font-size: 15px;
   font-weight: 400;
   letter-spacing: -0.0024em;
   line-height: 21px;
   opacity: 0.5;
}

.active-m-content {
   cursor: pointer;
   border: 1px solid #6c27ff !important;
   border-radius: 15px !important;
}

.input[type="radio"]:focus + label {
   outline: none;
}
.p-select-header {
   background: linear-gradient(180deg, #ddc4ff, hsla(0, 0%, 100%, 0) 12.79%);
   background-color: #fff;
}

.select-trip-modal .modal-dialog .modal-content {
   height: 346px !important;
   width: 390px !important;
}
.p-select-content {
   border-radius: 33px;
}

.selected-trip-border {
   border: 1px solid #6c27ff !important;
}

.itenery-trip-width {
   width: 665px !important;
   border-radius: 15px;
}

.icon-style {
   height: 24px !important;
   width: 24px !important;
   object-fit: contain;
}

footer {
   margin-top: auto;
}

/* Responsive for other devices */

@media (min-width: 1199px) and (max-width: 1440px) {
}
@media (min-width: 991px) and (max-width: 1200px) {
}

@media (max-width: 767px) {
   .activity-header {
      padding-top: 72px !important;
   }
   .help-modal-home {
      padding-top: 72px !important;
   }
   .activity-header-bookmark {
      padding-top: 72px !important;
   }
   .activity-header-legal {
      top: 72px;
   }
   .privacy-policy {
      padding-top: 145px !important;
   }

   .padding-top-mobile {
      padding-top: 70px !important;
      padding-bottom: 70px !important;
   }
   .trundle-header {
      display: none;
   }
   .activity-header div {
      align-items: stretch !important;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      border-radius: 0px !important;
      justify-content: start !important;
   }
   .trundle-header-top-margin {
      margin-bottom: 0px !important;
   }
   .plan-trip-fluid {
      bottom: 68px;
   }
   .main-banner {
      background-image: url("../images/background/Banner_moble.svg") !important;
      background-position: center !important;
      height: 530px !important;
   }

   .slider-mobile-top {
      top: 522px !important;
   }
   .documentry-hero-container {
      position: absolute;
      top: 26px;
      left: 0px;
      text-align: center;
   }
   .contact-us-btn {
      font-size: 15px;
      padding: 5px 15px !important;
   }
   .activity-header div::-webkit-scrollbar {
      display: none;
   }

   .activity-header-bookmark::-webkit-scrollbar {
      display: none;
   }

   .activity-header-bookmark {
      -ms-overflow-style: none;
      scrollbar-width: none;
   }

   .activity-header {
      border-radius: 0px !important;
   }
   .activity-header div {
      align-items: stretch !important;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap !important;
      overflow-x: auto;
      overflow-y: hidden;
      border-radius: 0px !important;
      justify-content: start !important;
   }

   .activity-header div div {
      flex-grow: 0;
      flex-shrink: 0;
      width: 150px;
      justify-content: center !important;
      align-items: center !important;
      display: flex !important;
   }
   .home-text-width {
      width: 100% !important;
   }
}
@media (min-width: 767px) and (max-width: 992px) {
   .trip-card .media .media-body div button {
      font-size: 11px;
      padding: 15px;
   }
   .more-detail-recomend .progress {
      height: 6px;
      min-width: 176px;
   }
}
@media (min-width: 566px) and (max-width: 768px) {
   .otp-v-header {
      /* box-shadow: none !important; */
   }
   .itenery-trip-width {
      width: auto !important;
   }
}

@media (max-width: 600px) {
   .activity-header-bookmark {
      align-items: stretch !important;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      border-radius: 0px !important;
      justify-content: start !important;
   }
   .activity-header-bookmark .nav-item {
      flex-grow: 0;
      flex-shrink: 0;
      width: 130px;
      justify-content: center;
      align-items: center;
      display: flex;
   }

   .activity-header-bookmark::-webkit-scrollbar {
      display: none;
   }

   .activity-header-bookmark {
      -ms-overflow-style: none;
      scrollbar-width: none;
   }
}

@media (min-width: 449px) and (max-width: 567px) {
   .itenery-trip-width {
      width: auto !important;
   }
   .main-banner {
      height: 475px !important;
   }
   .slider-mobile-top {
      top: 467px !important;
   }
   .documentry-hero-container h2 {
      font-size: 30px;
   }
}

@media (max-width: 339px) {
}

@media (max-width: 419px) {
   .home-text-width {
      margin-top: 20px;
   }
}

@media (min-width: 379px) and (max-width: 450px) {
   .otp-v-header {
      box-shadow: none !important;
   }

   .itenery-trip-width {
      width: auto !important;
   }
   .main-banner {
      height: 412px !important;
   }
   .slider-mobile-top {
      top: 402px !important;
   }
   .documentry-hero-container h2 {
      font-size: 25px;
   }
   .privacy-list {
      padding-left: 10px !important;
   }
}
@media (min-width: 319px) and (max-width: 380px) {
   .privacy-list {
      padding-left: 10px !important;
   }
   .documentry-hero-container h2 {
      font-size: 22px !important;
   }
   .main-banner {
      height: 380px !important;
   }
   .slider-mobile-top {
      top: 354px !important;
   }
   .otp-v-header {
      box-shadow: none !important;
   }
   .otp-input input {
      width: 45px !important;
      padding: 0px !important;
   }
   .documentry-hero-container h2 {
      font-size: 33px;
   }
   .itenery-trip-width {
      width: auto !important;
   }
   .carousel-item .days {
      font-size: 14px !important;
   }
   .date-duration {
      margin-left: 10px;
   }
   .activity-header .nav-item .nav-link {
      margin-right: 10px;
      margin-left: 10px;
      padding-bottom: 7px !important;
      margin-top: 7px !important;
   }
}

.country-radiobutton-c {
   -webkit-appearance: none;
   position: relative;
   width: 14px;
   height: 14px;
   border-radius: 50%;
   background: #fff;
   border: 1px solid #ddd !important;
   width: 20px;
   height: 20px;
   cursor: pointer;
   opacity: 1;
}

.p-content-select h3 {
   font-size: 17px;
   text-align: center;
   font-weight: 600;
}

.select-modal-trip .modal-dialog .modal-content {
   height: 500px !important;
   max-width: 78%;
   overflow: scroll;
   background: linear-gradient(180deg, #ddc4ff, hsla(0, 0%, 100%, 0) 12.79%);
   background-color: #fff;
   border-radius: 35px;
}

.select-modal-trip .modal-dialog .modal-content::-webkit-scrollbar {
   display: none;
}

.select-modal-trip .modal-dialog .modal-content {
   -ms-overflow-style: none;
   scrollbar-width: none;
}

.bookmarked-bg {
   background-color: rgba(61, 103, 255, 1);
   top: 20px;
   left: 20px;
   width: 145px;
   border-radius: 10px !important;
}

.bookmarked-bg p {
   font-weight: 600;
   font-size: 12px;
}

.bookmark-delete-button {
   right: 10px;
   top: 20px;
   background-color: #f0e9ff;
   width: 35px;
   height: 35px;
   border-radius: 50% !important;
}

.header-main {
   margin-top: 82px;
}

.modal-common-height .modal-body::-webkit-scrollbar {
   display: none;
}
.modal-common-height .modal-body {
   background: linear-gradient(180deg, #ddc4ff, hsla(0, 0%, 100%, 0) 12.79%);
}

.modal-common-height .modal-content .modal-title {
   font-size: 17px;
   font-weight: 500;
}

.modal-common-height .modal-content .modal-sub-title {
   font-size: 12px;
   font-weight: 600;
   line-height: 14px;
   text-align: left;
   color: rgba(159, 155, 163, 1);
}

.view-details-modal .modal-content,
.modal-common-height .modal-content,
.spending-modal-one .modal-content,
.spending-modal .modal-content,
.modal-header .modal-dialog .modal-content,
.profile-form-modal .modal-content {
   max-height: 83vh;
   max-width: 390px;
   height: 690px;
}

.disabled-btn {
   opacity: 0.6;
}

.view-details-modal {
   border-radius: 35px !important;
}

.view-details-modal .modal-content .modal-body .product-image {
   height: 260px !important;
   object-fit: cover;
   object-position: center;
   width: 100%;
}
.view-details-modal .modal-content .modal-body,
.modal-common-height .modal-body,
.spending-modal .modal-content .modal-body,
.profile-form-modal .modal-content .modal-body {
   border-radius: 35px 35px 0px 0px;
   max-height: 625px;
   overflow: auto;
   height: 100%;
}

.spending-modal .modal-content .modal-body::-webkit-scrollbar {
   display: none;
}

.profile-form-modal .modal-content .modal-body::-webkit-scrollbar {
   display: none;
}

.view-details-modal .modal-content .modal-body::-webkit-scrollbar {
   display: none;
}

.view-details-modal
   .modal-content
   .modal-body
   .product-detail
   .position-absolute {
   top: 230px;
   border-radius: 35px 35px 0px 0px;
   background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0)
   );
   backdrop-filter: blur(11px);
   -webkit-backdrop-filter: blur(10px);
}

.view-details-modal
   .modal-content
   .modal-body
   .product-detail
   div
   .product-name {
   font-size: 19px;
   font-weight: 500;
}

.view-details-modal
   .modal-content
   .modal-body
   .product-detail
   div
   .product-category {
   font-size: 15px;
   font-weight: 400;
}

.view-details-modal .modal-content .modal-body div .entry {
   border: 1px solid rgba(220, 219, 221, 1);
   border-radius: 15px;
}
.view-details-modal .modal-content .modal-body div .entry .free,
.view-details-modal .modal-content .modal-body div .address div .address-title {
   font-weight: 500;
   font-size: 17px;
}

.view-details-modal .modal-content .modal-body div .entry .pass-icon,
.view-details-modal .modal-content .modal-body div .address .pass-icon {
   width: 24px;
   height: 24px;
   object-fit: none;
}

.view-details-modal
   .modal-content
   .modal-body
   div
   .location
   div
   .get-direction {
   color: #6c27ff;
   font-weight: 600;
}

.view-details-modal .modal-content .modal-footer {
   height: 72px;
   border-radius: 35px;
   box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;
}

.view-details-modal .modal-content .modal-body .bookmark-icon {
   top: 208px;
   z-index: 1;
   right: 30px;
}

.view-details-modal .modal-content .saved-itenerary-modal {
   border-radius: 35px !important;
   height: 100%;
   max-height: none;
}

.help-modal .modal-head {
   font-size: 17px;
   font-weight: 500;
   margin-bottom: 10px !important;
}

.help-modal .modal-content .modal-body {
   max-height: none;
   border-radius: 35px;
   background: linear-gradient(180deg, #ddc4ff, hsla(0, 0%, 100%, 0) 12.79%);
}

.help-modal .hr-height {
   height: 1px !important;
}

.form-label-header-outline:focus {
   outline: none !important;
}

.css-qbdosj-Input input:focus {
   outline: none !important;
}
.remove-backround-product-show {
   background: white !important;
}

.cursor-none {
   cursor: default !important;
}

.bg-bookmark-mobile {
   background-color: rgb(108 39 255 / 90%);
}

.moment-created-at .created-at,
.date,
.time {
   font-size: 12px;
   font-weight: bold;
}

.sign-up-nav {
   color: rgb(108 39 255 / 90%);
   font-size: medium;
}

.place-card:hover {
   box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.2);
}

.react-icon {
   color: #989da2 !important;
}

.dont-account {
   font-size: 13px;
   font-weight: 400;
}

.p-button-login {
   background-color: rgba(240, 233, 255, 1);
}

.carousel-control-next {
   background-color: rgba(0, 0, 0, 0.67);
   width: 22px;
   border-radius: 50%;
   height: 22px;
   top: 34px;
}

.carousel-control-prev {
   background-color: rgba(0, 0, 0, 0.67);
   width: 22px;
   border-radius: 50%;
   height: 22px;
   top: 34px;
}

.carousel-control-next-icon {
   width: 10px;
   height: 10px;
}

.carousel-control-prev-icon {
   width: 10px;
   height: 10px;
}

.carousel-inner {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   border-radius: 15px;
   height: 84px;
   align-items: center !important;
   display: flex;
   background-color: white;
}

.carousel-item .days {
   font-size: 20px;
   font-weight: 700;
}

.carousel-item .desination {
   font-size: 13px;
   /* color: ; */
}

.carousel-indicators {
   bottom: -27px;
   gap: 10px;
}

.carousel-indicators button.active {
   background: rgba(81, 18, 214, 1) !important;
}

.carousel-indicators button {
   /* margin: 10px; */
   padding: 0px;
   width: 10px !important;
   height: 10px !important;
   background: rgba(196, 168, 255, 1) !important;
   border-radius: 100%;
   border-top: 0px !important;
   border-bottom: 0px !important;
}

.carousel-caption {
   position: unset;
}

.date-duration {
   background: rgba(240, 233, 255, 1);
   border-radius: 12px;
}

.date-duration p {
   color: rgba(108, 39, 255, 1);
   font-weight: 400;
   font-size: 13px;
}

.bottom-process-btn {
   position: absolute;
   bottom: 20px;
   left: 50%;
   transform: translateX(-50%);
   width: 100%;
}

.fixed-bottom-proceed {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
}

.is-representational {
   top: 200px;
   font-size: 13px;
}

.is-representational-home {
   top: 108px;
   font-size: 10px;
}

.is-representational-card {
   top: 106px;
   font-size: 13px;
}

.is-representational-bookmark {
   top: 106px;
   font-size: 13px;
}
.small-description {
   font-size: 13px !important;
   opacity: 60%;
}

.privacy-title {
   font-size: 23px;
   font-weight: 500;
}

.privacy-content {
   font-size: 17px;
   font-weight: 400;
   text-align: justify;
}

.privacy-list {
   list-style-type: none;
   padding-left: 20px;
}
.privacy-list li {
   font-size: 17px;
   font-weight: 400;
   text-align: justify;
   color: rgb(33 37 41 / 75%) !important;
   display: flex;
   align-items: start;
}
.last-update-text {
   font-size: 17px;
   color: rgba(29, 0, 92, 1);
}

.trip-slider {
   top: -93px;
   position: absolute;
}

.country-slide {
   align-items: stretch;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   overflow-x: auto;
   overflow-y: hidden;
   padding-top: 12px;
   padding-bottom: 12px;
}

.country-slide,
.spending-modal-one .modal-body::-webkit-scrollbar {
   display: none;
}
.spending-modal-one .modal-content .modal-footer div button {
   border-radius: 35px !important;
}

.spending-modal-one .modal-content .modal-body {
   border-radius: 35px;
}

.country-slide {
   -ms-overflow-style: none;
   /* scrollbar-width: none; */
}

.add-spending-button {
   background: rgba(108, 39, 255, 1);
   border-radius: 100px;
   color: white;
   height: 48px;
   top: 160px;
}

.spending-modal .title {
   font-size: 17px;
   font-weight: 500;
}
.spending-modal .modal-content {
   background: linear-gradient(
      180deg,
      #ddc4ff 0%,
      rgba(255, 255, 255, 0) 31.03%
   );
   background-color: white;
}

.spending-modal-one .modal-body {
   max-height: 625px;
   overflow: auto;
   overflow: auto;
}

.spending-modal .modal-content .spending-input {
   backdrop-filter: blur(25px);
   border: 1px solid #e2d4ff;
   border-radius: 15px;
   padding: 14px 25px;
}

.spending-modal .modal-content .spending-input input,
.spending-modal .modal-content .spending-input select:focus-within {
   box-shadow: none;
}

.spending-form {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
}

.spending-modal-one .spendings {
   font-size: 15px;
   font-weight: 500;
}

.spending-modal-one .spending-head-bg {
   background: rgba(122, 60, 255, 1);
   border-radius: 35px !important;
}

.spending-modal-one .spending-head-bg .title {
   font-size: 17px;
   font-weight: 500;
}

.spending-modal-one .spending-head-bg .message-text,
.spending-modal-one .item-three .text,
.spending-modal-one .item-three .amount-two,
.spending-modal-one .map-item .category,
.spending-modal-one .map-item .date_ {
   font-size: 13px;
   color: rgba(159, 155, 163, 1);
}

.spending-modal-one .spending-head-bg .amount-text {
   font-size: 15px;
}

.spending-modal-one .spending-head-inner {
   border-radius: 25px;
}

.spending-modal-one .item-two .so-far {
   font-size: 15px;
   color: rgba(159, 155, 163, 1);
}

.spending-modal-one .item-two .amount {
   font-size: 24px;
   font-weight: 500;
}

.spending-modal-one .item-three .amount {
   font-size: 13px;
}

.spending-modal-one .map-item .amount {
   font-weight: 500;
}

.spending-total-input {
   width: 60px;
   outline: none;
}
.home_country_slide {
   align-items: stretch;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   overflow-x: auto;
   overflow-y: hidden;
   padding-top: 12px;
   padding-bottom: 12px;
}

.home_country_slide::-webkit-scrollbar {
   display: none;
}

.description-copy-right {
   font-size: 13px;
}

.activity-header-bookmark {
   border-radius: 0 0 35px 35px;
   background-color: white;
}

.activity-header-bookmark .nav-item button {
   color: #000000 !important;
}

.activity-header-bookmark .nav-item .nav-link.active {
   border-bottom: 2px solid !important;
   border-color: rgba(108, 39, 255, 1) !important;
   color: rgba(108, 39, 255, 1) !important;
   opacity: 1;
}

.activity-header-bookmark .nav-item .nav-link {
   padding-bottom: 15px !important;
   margin-top: 15px !important;
   color: rgba(36, 28, 45, 1) !important;
   opacity: 0.5;
}

.activity-header-legal {
   border-radius: 0 0 35px 35px;
}

.activity-header-legal::before {
   border: 5px solid !important
   ;
}

.activity-header-legal .nav-item button {
   color: #000000 !important;
}

.activity-header-legal .nav-item .nav-link.active {
   border-bottom: 2px solid !important;
   border-color: rgba(108, 39, 255, 1) !important;
   color: rgba(108, 39, 255, 1) !important;
   opacity: 1;
}

.activity-header-legal .nav-item .nav-link {
   padding-bottom: 15px !important;
   margin-top: 15px !important;
   color: rgba(36, 28, 45, 1) !important;
   opacity: 0.5;
}

.transparent-opacity {
   opacity: 0.3 !important;
}

.bg-tips-title {
   background-color: rgba(171, 222, 244, 1);
}

.bg-tips-title p {
   color: #000000 !important;
   opacity: 1 !important;
   font-style: normal !important;
   font-size: 13px !important;
   letter-spacing: 0.5px !important;
}

.delete-confirmation-modal .yes-btn {
   background-color: red;
   color: white;
   border: 0px;
}

.modal-top-hr {
   border: 3px solid rgba(60, 60, 67, 0.3);
   border-radius: 100px;
   position: absolute;
   top: 15px;
   width: 36px;
}
.modal-top-hr_ {
   border: 3px solid rgba(167, 167, 167, 1);
   border-radius: 100px;
   width: 36px;
}

.modal-close-btn {
   top: 15px;
   z-index: 1;
   height: 30px;
   width: 30px;
   border-radius: 50%;
   right: 25px;
}

.modal-close-btn :hover {
   border: 1px solid red !important;
}

.add-plus-btn {
   bottom: 100px;
   z-index: 1;
   position: fixed;
}

.outer-wrapper {
   min-height: 100vh;
}

.home-card {
   height: 115px;
}

.contact-form-label {
   font-size: 13px;
   font-weight: 500;
   margin-bottom: 8px;
}

.contact-us-form div input:focus-within {
   box-shadow: none;
   border: 1px solid #eee;
}

.contact-us-form div select:focus-within {
   box-shadow: none;
   border: 1px solid #eee;
}

.contact-us-form div input,
.contact-us-form div select,
.css-t3ipsp-control {
   outline: none;
   border: 1px solid #eee;
   padding-top: 16px;
   padding-bottom: 14px;
   border-radius: 10px;
}

.contact-us-form div .error {
   font-size: 13px;
   margin: 5px;
}

.contact-us-container {
   width: 605px;
   padding: 40px;
   border-radius: 31.619px;
   background: #fff;
   box-shadow: 0px 4px 36px 0px rgba(101, 101, 101, 0.15);
}

.contact-submit {
   border-radius: 100px;
   background: #6f27ff;
   display: flex;
   padding: 8px 115px;
   justify-content: center;
   align-items: center;
}

.contact-us-form {
   gap: 24px;
}

.dropdown-arrow-position {
   right: 20px;
   top: 50px;
}

.css-t3ipsp-control:focus-within {
   box-shadow: none;
   border-radius: 10px;
   outline: none;
   border: 1px solid #eee !important;
   height: 55.99px !important;
   padding: 0px !important;
}

.css-13cymwt-control {
   box-shadow: none;
   border: 1px solid #eee;
   border-radius: 10px;
   outline: none;
   border: 1px solid #eee !important;
}

.css-1u9des2-indicatorSeparator,
.css-1xc3v61-indicatorContainer,
.css-1hb7zxy-IndicatorsContainer {
   display: none !important;
}

.country-code-contact-us .css-13cymwt-control {
   border-radius: 0px !important;
   border-left: 0px !important;
   border-top: 0px !important;
   border-bottom: 0px !important;
   background: transparent !important;
}

.country-code-contact-us .css-t3ipsp-control:focus-within {
   border-radius: 0px !important;
   border-left: 0px !important;
   border-top: 0px !important;
   border-bottom: 0px !important;
   background: transparent !important;
}

.country-code-contact-us .css-1nmdiq5-menu {
   width: 100px !important;
}

.contact-us-btn {
   border-radius: 100px;
   padding: 10px 20px;
   font-weight: 500;
}

.box-shadow {
   box-shadow: 0px 4px 8px 0px rgba(132, 112, 176, 0.1);
}

.help-plan-my-trip-button {
   border-radius: 100px;
   padding-left: 16px;
   padding-right: 16px;
   padding-top: 9px;
   padding-bottom: 9px;
   border: 0px;
   font-size: 15px;
   font-weight: 500 !important;
   height: 36px;
}

.help-plan-my-trip-button a {
   font-size: 15px;
   font-weight: 500 !important;
   color: #6c27ff;
}

.home-text-width {
   width: 50%;
}
